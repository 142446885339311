<template>
  <div v-if="loading" style="max-height: 100px">
    <loading></loading>
  </div>
  <div v-else class="p-4">
    <b-row>
      <b-col col md="7" sm="12" class="text-left p-0">
        <div id="edge-bundling" ref="edge-bundling-chart"><svg></svg></div>
        <div>
          <!-- Add an extra margin on smaller screens -->
          <div class="d-sm-block d-md-none d-lg-none p-0 mt-4"></div>
          <b-card no-header no-body class="text-left">
            <b-card-body class="p-0">
              <div class="legend text-left text-muted">
                <b-row class="m-0 pl-1">
                  <b-col class="legend-item pl-0">
                    <b-icon icon="square-fill" class="text-red"></b-icon>
                    <span class="ml-2">Increase</span>
                  </b-col>
                  <b-col class="legend-item">
                    <b-icon icon="square-fill" class="text-blue"></b-icon>
                    <span class="ml-2">Decrease</span>
                  </b-col>
                  <b-col class="legend-item">
                    <b-icon icon="square-fill" class="text-black"></b-icon>
                    <span class="ml-2">Increase & Decrease</span>
                  </b-col>
                </b-row>
              </div>
            </b-card-body>
            <b-card-footer footer-class="text-left p-0 pl-1 m-0">
              <small class="text-muted">
                Click on the node or the edges to interact with the connectome
              </small>
            </b-card-footer>
          </b-card>
        </div>
      </b-col>
      <b-col col md="5" sm="12">
        <div class="d-flex flex-column text-left">
          <div>
            <searchable-select
              :options="domainOption"
              label="Domain"
              placeholder="Select Domain"
              @on-input="updateFilters('domain', $event)"
              :selectMultiple="true"
              :displayTextAndValue="false"
            >
            </searchable-select>
          </div>

          <!-- TODO: dynamically fetch options. Remove hard coded option -->
          <searchable-select
            :options="[
              { text: 'mice', value: 'mice' },
              { text: 'rat', value: 'rat' },
            ]"
            label="Animal Model"
            placeholder="Select Animal Model"
            @on-input="updateFilters('animalModel', $event)"
            :selectMultiple="true"
            :displayTextAndValue="false"
          ></searchable-select>

          <!-- TODO: Dynamically fetch options. Remove hard coded option -->
          <searchable-select
            :options="[
              { text: 'increase', value: 'increase' },
              { text: 'decrease', value: 'decrease' },
              { text: 'no change', value: 'no change' },
            ]"
            label="Direction Summary"
            placeholder="Selection Direction Summary"
            @on-input="updateFilters('directionSummary', $event)"
            :selectMultiple="true"
            :displayTextAndValue="false"
          >
          </searchable-select>

          <searchable-select
            :options="[
              { text: 'Opto Stimulation', value: 'opto stimulation' },
              { text: 'Opto Inhibition', value: 'opto inhibition' },
              { text: 'Chem Stimulation', value: 'chem stimulation' },
              { text: 'Chem Inhibition', value: 'chem inhibition' },
            ]"
            label="Methodology"
            placeholder="Select Methodology"
            @on-input="updateFilters('methodology', $event)"
            :selectMultiple="true"
            :displayTextAndValue="false"
          ></searchable-select>

          <searchable-select
            :options="behavioralTestsOptions"
            label="Behavioral Tests"
            placeholder="Select Behavioral Tests"
            @on-input="updateFilters('behavioralTests', $event)"
            :selectMultiple="true"
            :displayTextAndValue="false"
          ></searchable-select>

          <searchable-select
            :options="brainRegionsOptions"
            label="Brain Region"
            placeholder="Select Brain Region"
            @on-input="updateFilters('brainRegions', $event)"
            :selectMultiple="true"
            :displayTextAndValue="false"
          ></searchable-select>
        </div>
      </b-col>
    </b-row>

    <div v-if="floatingInfoBox != null">
      <floating-info-box
        :coords="floatingInfoBox.coords"
        :studies="floatingInfoBox.studies || []"
        :source="floatingInfoBox.source"
        :target="floatingInfoBox.target"
        @on-close="floatingInfoBox = null"
      ></floating-info-box>
    </div>
  </div>
</template>
<script>
/**
 * This component contains the hierarchical edge binding graph based on all the inputs available.
 */
import * as d3 from "d3";
import _ from "lodash";
import SearchableSelect from "../common/SearchableSelect.vue";
import FloatingInfoBox from "../common/FloatingInfoBox";
import AlphaRange from "../../mixins/AlphaRange";
import FirebaseApi from "../../api/FirebaseApi";
import Loading from "../common/Loading.vue";
export default {
  components: { SearchableSelect, FloatingInfoBox, Loading },
  mixins: [AlphaRange],
  name: "HierarchicalEdgeBundling2",
  props: [],
  data: function () {
    return {
      loading: true,
      // TODO: Document this
      floatingInfoBox: null,
      cluster: null,
      filters: {
        domain: [],
        animalModel: [],
        directionSummary: [],
        methodology: [],
        behavioralTests: [],
        brainRegions: [],
      },
      studies: [],
      brain: {},

      /**
       * List of all the behavioral tests that are available in the database. This should
       * be initialized by pulling the values from the database
       */
      behavioralTests: [],

      chartDiameter: 0,
    };
  },
  computed: {
    /**
     * This method returns all the studies after applying the filters defined
     * in the `filters` object in data props.
     */
    filteredStudies() {
      return _.chain(this.studies)
        .filter((study) => {
          const domainFilter = this.filters.domain || [];
          const animalModelFilter = this.filters.animalModel || [];
          const dirSummaryFilter = this.filters.directionSummary || [];
          const methodolyFilter = this.filters.methodology || [];
          const behavioralTestsFilter = this.filters.behavioralTests || [];
          const brainRegionsFilter = this.filters.brainRegions || [];

          function contains(filter, value) {
            if (filter.length === 0) {
              return true;
            }

            return _.includes(filter, value);
          }

          const intersection = (filter, value) => {
            if (filter.length === 0) {
              return true;
            }

            return _.intersection(filter, value).length > 0;
          };

          // If the filter is specified,
          // exclude this study if it doesn't match the domain filter
          if (!intersection(domainFilter, study.domain)) return false;

          // If the filter is specified, only include this study if it's animal
          // modal matches one of the filters
          if (!contains(animalModelFilter, study.animalModel)) return false;

          if (!intersection(dirSummaryFilter, study.directionSummary))
            return false;

          if (!intersection(methodolyFilter, study.methodology)) return false;

          if (!intersection(behavioralTestsFilter, study.behavioralTests))
            return false;

          if (
            !intersection(brainRegionsFilter, [
              study.targetFrom,
              study.targetTo,
            ])
          )
            return false;

          return true;
        })

        .value();
    },

    /**
     * This computed method returns all the brain regions that do not have any regions (i.e leaf nodes) by applying
     * filters as necessary.
     */
    brainRegions() {
      // make a clone to avoid modifying the actual object
      const brain = { ...this.brain };

      // Iterate through all the studies and create a map which uses brain region code as key
      // and a boolean for value. The boolean value indicates whether this brain region is currently
      // studied (i.e. is either targetFrom or targetTo on at least one dataset).
      // For example {CNY: true, EPd: true}.
      // Note: In a hypothetical dataset, if targetTo is CNY and targetFrom is SSP, it means
      // both SNY and SSP were studied

      const map = {};
      this.studies.forEach((study) => {
        const targetFrom = study.targetFrom;
        const targetTo = study.targetTo;

        map[targetFrom] = true;
        if (targetTo != null) {
          map[targetTo] = true;
        }
      });

      /**
       * Given a map of studies and a brain hierary, this method recursively traverses through
       * the brain hierary and removes all path from the root to the leaf node if the leaf
       * node has never been studied.
       *
       * For example:
       *  root = {
       *    "code":"A",
       *    "regions":[{
       *       "code":"B",
       *       "regions":[
       *          {
       *             "code":"C"
       *          },
       *          {
       *              "code": "D"
       *          },
       *          {
       *             "code": "F"
       *          }
       *       ]
       *    },
       *    {
       *      code: "F",
       *      regions: []
       *    }]
       * }
       *
       * map = [{targetFrom: C, targetTo: D}, {targetFrom: E}]
       * This method would remove the path from A to F entirely because there is no node between
       * A to F that is studied. And, this method would also remove the node F because it has not bee studied
       *
       * For a visual explanation, look at the image docs/resources/brainHierarchyTrim.png
       * @param {*} root
       * @param {*} map
       * @returns
       */
      const removeUnstudiedPath = function (root) {
        if (root.regions == null || root.regions.length == 0) {
          return map[root.code] ? root : null;
        }

        const regions = [];
        for (let i = 0; i < (root.regions || []).length; i++) {
          const node = removeUnstudiedPath(root.regions[i], map);
          if (node != null) {
            regions.push(node);
          }
        }

        if (regions.length == 0) return null;
        root.regions = regions;
        return root;
      };

      removeUnstudiedPath(brain);
      return brain;
    },

    domainOption() {
      return [
        { text: "Aggression", value: "aggression" },
        { text: "Anxiety", value: "anxiety" },
        { text: "Depression", value: "depression" },
        { text: "Dominance", value: "dominance" },
        { text: "Fear", value: "fear" },
        { text: "Social Memory", value: "socialMemory" },
        { text: "Sociability", value: "sociability" },
        { text: "Social Reinforcement", value: "socialReinforcement" },
      ];
    },

    behavioralTestsOptions() {
      return this.behavioralTests.map((test) => {
        return {
          text: test,
          value: test,
        };
      });
    },

    /**
     * Returns a sorted list of all the brain regions that are currently studied.
     * It includes both TargetFrom and TargetTo Fields
     */
    studiedBrainRegions() {
      const set = new Set(); // used to distinguish unique values
      this.studies.forEach((study) => {
        const targetFrom = study.targetFrom;
        const targetTo = study.targetTo;

        if (!set.has(targetFrom)) {
          set.add(targetFrom);
        }

        if (targetTo != null && !set.has(targetTo)) {
          set.add(targetTo);
        }
      });
      return Array.from(set);
    },

    brainRegionsOptions() {
      return this.studiedBrainRegions.map((regionName) => {
        return {
          text: regionName,
          value: regionName,
        };
      });
    },
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    updateFilters(key, value) {
      this.filters[key] = value;

      this.drawNodes();
    },

    /**
     * 1. Parent should be the name of the brain region (target from)
     * 2. Child should be the name of the brain region (rarget to)
     * 3. Rest of the data should be in the "data" field
     */
    // eslint-disable-next-line no-unused-vars
    pathWayHierarchy() {
      const hierarchy = d3.hierarchy(this.brainRegions, (data) => {
        return data.regions;
      });

      //

      // This object will essentially loop through filtered studies and build an index (the key will be the name)
      // of the brain region studied (i.e. targetFrom) and values will be an array of actual studies
      // where that brain region is "targetFrom"
      const studies = {};

      // Iterate through all the studies. and fill the array with the studies
      for (const study of this.filteredStudies) {
        studies[study.targetFrom] = [
          ...(studies[study.targetFrom] || []),
          study,
        ];

        // const sourceNode = study.targetFrom;
        // const targetNode = study.targetTo;

        // if (study[sourceNode] == null) {
        //   studies[sourceNode] = [];
        // }
        // studies[sourceNode].push(study);

        // if (targetNode != null) {
        //   if (study[targetNode] == null) {
        //     studies[targetNode] = [];
        //   }
        //   studies[targetNode].push(study);
        // }
      }

      // Now iterate through all the nodes and if it has any studies, append it to data
      hierarchy.leaves().forEach((node) => {
        // get all the studies on this target node
        const thisStudies = studies[node.data.code] || [];

        node.data.studies = thisStudies;
      });

      return hierarchy;
    },

    /**
     * This method returns links between each nodes which are studied (i.e. the data object must have studies attribute).
     *
     * @param {Object} nodes Array of nodes between which we want to find links.
     * @returns {Array<Object>} Array of links between the nodes. Each Link object looks like this:
     * {
     *  source: Node,
     *  target: Node,
     *  studies: Array<Study> // Array of studies between source and target (irrespective of the direction)
     *  path: Array<Node> // Array of Nodes in the path between the source and target (irrespective of the direction)
     * }
     */
    getLinks(nodes) {
      // This object caches the nodes in the parameter by using their code as keys. It is used to
      // quickly find the node by it's code later on.
      const brainRegionCodeMap = {};
      // const links = [];

      // This map is used to collect a set of studies between two brain regions (a source and a target).
      // This is used to avoid duplicate links between two nodes.
      const studiesMap = {};

      // This method will create a key using the sourceNode and targetNode and add the study
      // to the [studiesMap] defined above. For a given set of sourceNode and targetNode, this method
      // will always generate the same key. For example, the key for (Source A and targetB) and (Source B and target A)
      // will always be the same.
      // eslint-disable-next-line no-unused-vars
      const addStudyToMap = (sourceNode, targetNode, study) => {
        // Sort the codes of each brain region by code and join them with "-" to form the key.
        // This ensures the the key is always going to be the same regardless of the order
        // of the source and target node
        const key = [sourceNode.data.code, targetNode.data.code]
          .sort()
          .join("-");

        // If the key is already in the map, then we add the study to the existing array
        // Otherwise add the study to the new array
        if (studiesMap[key]) {
          const studies = studiesMap[key].studies;
          studies.push(study);
        } else {
          studiesMap[key] = [study];

          const path = sourceNode.path(targetNode);
          studiesMap[key] = {
            source: sourceNode,
            target: targetNode,
            studies: [study],
            path: path,
          };
        }
      };

      nodes.forEach((node) => {
        const brainRegionCode = node.data.code;
        brainRegionCodeMap[brainRegionCode] = node;
      });

      // Now, for each studies of this brain region, create a link from the source to the target
      nodes.forEach((node) => {
        // Iterate through all the studies, and find path
        // between the two region in a study (i.e pathway study. If the study
        // is about a region, ignore it)
        if (node.data.studies) {
          // Get the d3path between nodes which have been studied
          node.data.studies.forEach((study) => {
            const targetToRegion = study.targetTo;

            // If this study studies only one brain region, it will not have target to. In that case, there is no
            // link
            if (targetToRegion == null) return;

            const targetToNode = brainRegionCodeMap[targetToRegion];

            // get the d3path between the node and the targetTo Node

            // links.push({ study, path, source: node, target: targetToNode });
            addStudyToMap(node, targetToNode, study);
          });
        }
      });
      // return links;
      return Object.values(studiesMap);
    },
    mouseoveredNode(node, d) {
      const code = d.data.code;

      const sourceLinks = d3.selectAll(`.link-source-${code}`);
      d3.selectAll(`.link-source-${code}, .link-target-${code}`)
        .classed(`link-source-${code}`, false)
        .classed(`link-target-${code}`, false)
        .classed(`link-hovered`, true)
        .raise();

      const targetNodeClasses = [];
      sourceLinks.data().forEach((node) => {
        targetNodeClasses.push(`.node-${node.target.data.code}`);
      });

      if (targetNodeClasses.length > 0) {
        d3.selectAll(targetNodeClasses.join(" ,")).classed(
          "node-hovered",
          true
        );
      }

      // all.attr("stroke-width", "8px").raise();
    },

    mouseoutedNode(node, d) {
      const code = d.data.code;

      d3.selectAll(`.link-hovered`)
        .classed(`link-hovered`, false)
        .classed(`link-source-${code}`, true)
        .classed(`link-target-${code}`, true);

      // Un-hover over all the nodes that this link connects
      const sourceLinks = d3.selectAll(`.link-source-${code}`);
      const targetNodeClasses = [];
      sourceLinks.data().forEach((node) => {
        targetNodeClasses.push(`.node-${node.target.data.code}`);
      });

      if (targetNodeClasses.length > 0) {
        d3.selectAll(targetNodeClasses.join(" ,")).classed(
          "node-hovered",
          false
        );
      }
    },

    /**
     * @param {object}
     * @param {object}
     * @param {object} mouseCoordinate Coordinate of the mouse E.g. {x: 12, y: 20}
     */
    // eslint-disable-next-line no-unused-vars
    mouseoveredLink(node, d, mouseCoordinate) {
      this.floatingInfoBox = { title: "x", coords: mouseCoordinate };
    },
    // eslint-disable-next-line no-unused-vars
    mouseoutedLink(node, d) {
      this.floatingInfoBox = null;
    },
    draw() {
      // The diameter should be 700px or 90% of the screen whichever is lowest
      d3.select("svg").selectAll("*").remove();

      const radius = this.chartDiameter / 2;
      // eslint-disable-next-line no-debugger
      debugger;
      // inner radius
      const clusterRadius = 0.75 * radius;

      const svg = d3
        .select("svg")
        .attr("width", this.chartDiameter)
        .attr("height", this.chartDiameter)
        .append("g")
        .attr("class", "drawing-space")
        .attr("transform", "translate(" + radius + "," + radius + ")");

      svg.append("g").attr("class", "brain-regions");
      svg.append("g").attr("class", "pathways");

      //Doc: https:// github.com/d3/d3-hierarchy#cluster_size
      this.cluster = d3.cluster().size([360, clusterRadius]);

      this.drawNodes();
    },
    /**
     * This method draws links between the given leaf nodes. When drawing the links, it respects the hierarchical
     * pathway between each of the leaf nodes (Look at the brain region hierarchy to understand more about the hierarchical
     * pathway).
     */
    drawLinks(leaves) {
      const vm = this;
      const svg = d3.select("svg").select(".pathways");
      // eslint-disable-next-line no-unused-vars
      const line = d3
        .radialLine()
        .curve(d3.curveBundle.beta(0.85))

        .radius(function (d) {
          return d.y;
        })
        .angle(function (d) {
          return (d.x / 180) * Math.PI;
        });

      // eslint-disable-next-line no-unused-vars
      let updateLink = svg
        .selectAll(".link-group")
        // eslint-disable-next-line no-unused-vars
        .data(this.getLinks(leaves), (link) => {
          return `${link.source.data.code}-${link.target.data.code}`;
        })
        .join(
          function (enter) {
            return (
              enter
                .append("g")
                .attr("class", "link-group")
                .append("path")

                // eslint-disable-next-line no-unused-vars
                .attr("class", (d) => {
                  // Create a set of direction summaries for the studies on this link (pathways).
                  // This way, we can stiringify the direction summary and build css class for the link
                  // The css class is responsible for assigning the color to the link
                  const directionSummarySet = new Set();
                  d.studies.forEach((study) => {
                    study.directionSummary.forEach(
                      directionSummarySet.add,
                      directionSummarySet
                    );
                  });

                  const classes = [
                    "link",
                    `link-${Array.from(directionSummarySet).join("-")}`,
                    `link-source-${d.source.data.code}`,
                    `link-target-${d.target.data.code}`,
                  ];

                  return classes.join(" ");
                })
                .attr("stroke-opacity", function (d) {
                  const alpha = vm.getLinkAlpha(d.studies.length);
                  return alpha;
                })
                .attr("d", function (d) {
                  return line(d.path);
                })
                // TODO: Split this into separate function and make it reusable
                .on("click", function (event, d) {
                  vm.linkClicked(d.source, d.target, event);
                })
            );
          },
          function (update) {
            return update;
          },
          function (exit) {
            return exit.remove();
          }
        );
      // .attr("d", line);
    },

    /**
     * TODO: Give better name to this function and document it well
     *
     *
     * // targetNode is null if node is clicked
     */
    linkClicked(sourceNode, targetNode, event) {
      const studies = [];
      const vm = this;

      // Get all the studies that was done on this pathway (i.e. the source and target have
      // to match in no particular order)
      vm.studies.forEach((study) => {
        if (study.targetTo == null) return;

        const targetFrom = study.targetFrom;
        const targetTo = study.targetTo;

        // If targetNode can be null (for node). If it is null, only match the sourceNode
        let pathMatch =
          [targetFrom, targetTo].includes(sourceNode.data.code) &&
          [targetFrom, targetTo].includes(targetNode.data.code);

        if (pathMatch) {
          studies.push(study);
        }
      });
      vm.floatingInfoBox = {
        coords: { x: event.clientX, y: event.clientY },
        studies,
        source: sourceNode.data,
        target: targetNode.data,
      };
    },

    /**
     * TODO: Give better name to this function and document it well
     *
     *
     * // targetNode is null if node is clicked
     */
    nodeClicked(node, event) {
      const studies = [];
      const vm = this;

      // Get all the studies that was done on this pathway (i.e. the source and target have
      // to match in no particular order)
      vm.studies.forEach((study) => {
        // Exclude studies on pathways and only keep region
        if (study.targetTo != null) return;

        const targetFrom = study.targetFrom;

        if (targetFrom === node.data.code) {
          studies.push(study);
        }
      });
      vm.floatingInfoBox = {
        title: "Test",
        coords: { x: event.clientX, y: event.clientY },
        studies,
        source: node.data,
        target: null,
      };
    },

    drawNodes() {
      const svg = d3.select("svg").select(".brain-regions");

      let root = this.pathWayHierarchy();

      this.cluster(root);

      // Get all the nodes
      const leaves = root.leaves();

      // eslint-disable-next-line no-unused-vars
      const vm = this;
      svg
        .selectAll(".node")
        .data(leaves, (node) => {
          return node.data.code;
        })
        .join(
          function (enter) {
            return enter
              .append("g")
              .attr("class", "node")
              .append("text")
              .attr("dy", "0.31em")
              .attr("transform", function (d) {
                return (
                  "rotate(" +
                  (d.x - 90) +
                  ")translate(" +
                  (d.y + 8) +
                  ",0)" +
                  (d.x < 180 ? "" : "rotate(180)")
                );
              })
              .attr("text-anchor", function (d) {
                return d.x < 180 ? "start" : "end";
              })
              .text(function (d) {
                // Text should contain the
                // code of this brain region
                // and in the bracket, the number
                // studies on this region (not including the
                // studies where this region was either end of the
                // pathway)
                let numStudies = 0;
                d.data.studies.forEach((study) => {
                  // Remember, the count should only include region (and not pahtway)
                  if (study.targetTo != null) return;

                  numStudies += 1;
                });

                return numStudies > 0
                  ? `${d.data.code} (${numStudies})`
                  : d.data.code;
              })
              .attr("class", function (node) {
                return `node-text node-${node.data.code}`;
              })
              .on("mouseover", function (event, d) {
                vm.mouseoveredNode(this, d);
              })
              .on("mouseout", function (event, d) {
                vm.mouseoutedNode(this, d);
              })
              .on("click", function (event, d) {
                vm.nodeClicked(d, event);
              });
          },

          function (update) {
            return update;
          }
        );

      this.drawLinks(leaves);
    },
    async init() {
      try {
        // eslint-disable-next-line no-unused-vars
        const studies = await FirebaseApi.getStudies();
        this.studies = studies;

        const brain = await FirebaseApi.getBrainHierarchy();
        this.brain = brain;

        const formMetadata = await FirebaseApi.getFormMetadata();
        this.behavioralTests = formMetadata.behavioralTests.sort();

        this.loading = false;

        this.$nextTick(function () {
          this.chartDiameter = this.computeChartDiameter();
          this.draw();
        });
      } catch (err) {
        console.error(err);
      }

      // eslint-disable-next-line no-unused-vars
      window.addEventListener("resize", (e) => {
        this.chartDiameter = this.computeChartDiameter();
        this.draw();
      });
    },
    computeChartDiameter() {
      return Math.min(
        700,
        this.$refs["edge-bundling-chart"].getBoundingClientRect().width
      );
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style>
* {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.node {
  /* font: 300 11px "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-size: 11px;
  fill: #414141;
}

.node-hovered,
.node:hover {
  fill: #a63d40;
  font-weight: 800;
  font-size: 13px;
  cursor: pointer;
}

.link {
  fill: none;
  pointer-events: stroke !important;
  stroke-width: 1.5px;
}

.link-hovered,
.link:hover {
  /* fill: red; */
  stroke-width: 4px !important;
  cursor: pointer;
  transition: stroke-width 0.3s;
}

.link-decrease {
  stroke: #227c9d;
  /* opacity: 0.8; */
}

.link-increase {
  stroke: #a63d40;
  /* opacity: 0.8; */
}

.link-increase-decrease,
.link-decrease-increase {
  stroke: #141514;
  /* opacity: 0.8; */
}

.text-blue {
  color: #227c9d;
}

.text-red {
  color: #a63d40;
}

.text-black {
  color: black;
}
</style>
